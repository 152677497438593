import * as React from "react"
import { PageProps } from "@/definitions"
import { Link } from "gatsby"

const NotFoundPage: React.FC<PageProps> = ({ data, location }) => {
  return (
    <main className="h-screen w-full flex flex-col justify-center items-center">
    <h1 className="text-9xl font-extrabold tracking-widest">404</h1>
    <div className="bg-[#FF6A3D] px-2 text-sm rounded rotate-12 absolute">
      Page Not Found
    </div>
    <button className="mt-5">
        <a
          className="relative inline-block text-sm font-medium group active:text-orange-500 focus:outline-none focus:ring"
        >
          <span
            className="absolute inset-0 transition-transform translate-x-0.5 translate-y-0.5 group-hover:translate-y-0 group-hover:translate-x-0"
          ></span>
  
          <span className="relative block px-8 py-3 border border-current">
            <Link to="/">Go Back to Main Page</Link>
          </span>
        </a>
      </button>
  </main>
  )
}

export default NotFoundPage